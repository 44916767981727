/*******************************
    User Variable Overrides
*******************************/

.accordion.ui.styled {
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2) !important;
    border-radius: 8px !important;
}

.accordion.ui.fluid .title {
    color: rgb(0, 0, 0) !important;
    cursor: pointer;
}