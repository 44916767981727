/*******************************
    User Variable Overrides
*******************************/

.ui.form .disabled.fields .field textarea, 
.ui.form .disabled.field textarea
{
    pointer-events: auto;
}

/* This is a default style but is being overridden somehow */
.ui.form .ui.error.input > input{
    background-color: #FFF6F6;
    border-color: #E0B4B4;
    color: #9F3A38;
    box-shadow: none;
}
