/*******************************
    User Variable Overrides
*******************************/

.disabled.field {
    opacity: 1;
}

.ui.form {
    opacity: 1;
}