/*******************************
         Site Overrides
*******************************/


div.ui.icon.input > i.icon {
    color: black;
    opacity: 1;
}

div.ui.icon.input > i.icon {
    color: black;
    opacity: 1;
}

input::placeholder {
  color: #666 !important;
 }