.indent-1 {
  padding-left: 2em !important;
}

/* Overrides to semantic.css for disabled */

.ui.disabled.input > input,
.ui.input:not(.disabled) input[disabled] {
  border-color: transparent;
  opacity: 1;
}
.ui.form .field.disabled :disabled {
  border-color: transparent;
  opacity: 1;
}
.ui.form .field.disabled > label,
.ui.form .fields.disabled > label {
  opacity: 1;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 1;
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 1;
  border-color: transparent;
}

.ui.menu .item.disabled,
.ui.menu .item.disabled:hover {
  cursor: not-allowed !important; /* Couldn't see another way to get this to override reliably, so had to use !important; */
}

*:not(i) {
  font-family: "Poppins", "Open Sans", Arial, Helvetica, sans-serif !important;
}

input:focus {
  border-color: #f8991d !important;
  color: #444 !important;
}

textarea:focus {
  border-color: #f8991d !important;
  color: #444 !important;
}

/* Firefox */
.ui.menu {
  scrollbar-color: #444 transparent;
}

/* width */
.ui.menu::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.ui.menu::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.ui.menu::-webkit-scrollbar-thumb {
  background: #f2f1f1;
}

/* Handle on hover */
.ui.menu::-webkit-scrollbar-thumb:hover {
  background: #f8991d;
}

.mouse-pointer {
  cursor: pointer;
}

.mouse-pointer-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}

.ui.vertical.menu .item:before {
  background: transparent !important;
}
.ui.menu .item:before {
  background: transparent !important;
}

.ui.accordion {
  width: auto !important;
}

/* ace_editor */
.ace_editor,
.ace_editor * {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas",
    monospace !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
}
.ace_editor .ace_scrollbar::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}
.ace_editor .ace_scrollbar::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #272822; /* Matches ace monokai */
  border-radius: 10px;
}
.ace_editor .ace_scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
}


/* Tooltip container */
.tooltip {
   position: relative;
   display: inline-block;
  /* border-bottom: 1px dotted black;  If you want dots under the hoverable text */
  margin: 5px 5px 5px 5px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: rgb(128, 128, 128);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;

  top: -5px;
  left: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 40%; /* At the bottom of the tooltip */
  right: 100%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgb(128, 128, 128) transparent transparent;
}

.ui.dropdown .item .left.dropdown.icon, .ui.dropdown .left.menu .item .dropdown.icon {
  padding-right: 5px;
}

/*rc-slider style when handle is being dragged*/
.rc-slider-handle-dragging {
  box-shadow: 0 0 0 2px #f8991d !important;
}
/*rc-slider style when handle has focus*/
.rc-slider-handle:focus-visible {
  box-shadow: 0 0 0 3px #f8991d !important;
}

.withIdCSV {
  background-color: rgba(255, 158, 0, 0.1);
}

.withoutIdCSV {
  background-color: rgba(56, 255, 38, 0.1); 
}

.sortable-ghost {
  opacity: 0.4;
}

.question-row {
  padding: 8px;
  margin: 4px 0;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.drag-handle {
  cursor: grab;
  margin-right: 8px;
}