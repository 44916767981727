/*******************************
         Site Overrides
*******************************/



a.ui.button.primary {
  color: black;
  &:hover{
     color: black
    }
  &:focus{
     color: black
    }
}

button.button.ui.basic.borderless {
    box-shadow: none !important;
}

button.ui.button.primary {
  color: black;
  &:hover{
   color: black
  }
    &:focus{
       color: black
      }
}

button.ui.button.orange {
  color: black;
  &:hover{
   color: black
  }
  &:focus{
     color: black
    }
}